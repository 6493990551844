import { render, staticRenderFns } from "./OrderEqualRecord.vue?vue&type=template&id=3c1d9452&scoped=true&"
import script from "./OrderEqualRecord.vue?vue&type=script&lang=js&"
export * from "./OrderEqualRecord.vue?vue&type=script&lang=js&"
import style0 from "./OrderEqualRecord.vue?vue&type=style&index=0&id=3c1d9452&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c1d9452",
  null
  
)

export default component.exports