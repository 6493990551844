<!-- 厂家供货表 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20">
      <!-- 订单号 -->
      <FormItem style="marginLeft: 15px;">
        <span>订单号：</span>
        <Input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.ebeln" />
      </FormItem>
      <!-- 订单时间 -->
      <FormItem style="marginLeft: 20px;">
        <span>订单时间：</span>
        <DatePicker type="date" :value="queryFrom.order_time_start" @on-change="changeTime1($event, 1)" placeholder="请选择" class="iviewIptWidth250"></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker type="date" :value="queryFrom.order_time_end" @on-change="changeTime1($event, 2)" :options="options" placeholder="请选择" class="iviewIptWidth250"></DatePicker>
      </FormItem>
      <!-- 更新时间 -->
      <FormItem style="marginLeft: 65px;">
        <span>更新时间：</span>
        <DatePicker type="date" @on-change="changeTime2($event, 1)" :value="queryFrom.update_time_start" placeholder="请选择" class="iviewIptWidth250"></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker type="date" @on-change="changeTime2($event, 2)" :options="options" :value="queryFrom.update_time_end" placeholder="请选择" class="iviewIptWidth250"></DatePicker>
      </FormItem>
      <!-- 申请单号： -->
      <FormItem>
        <span>申请单号：</span>
        <Input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.apply_order_code" />
      </FormItem>
      <FormItem style="marginLeft: 20px;">
        <span>申请者：</span>
        <Input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.afnam" />
      </FormItem>
      <FormItem style="marginLeft: 20px;">
        <span>采购组：</span>
        <Input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.ekgrp" />
      </FormItem>
      <FormItem style="marginLeft: 20px;">
        <span>备注：</span>
        <Input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.potxt" />
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query()">查询</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="IshandImport = true">手动导入</span>
      </FormItem>
    </Form>

    <!-- 手动导入弹窗 -->
    <Modal v-model="IshandImport" title="查询条件" cancel-text @on-cancel="CancelHandImport" width="568">
      <div class="modalContent">
        <p class="modaltitle">请输入订单号：</p>
        <Input placeholder="请输入" class="iviewIptWidth250 MarginBottom20" v-model="handleList.ebeln" />
        <p class="modaltitle">请输入更新时间：</p>
        <DatePicker type="date" @on-change="changeTime3($event, 1)" v-model="handleList.update_time_start" placeholder="请选择" class="iviewIptWidth250"></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker type="date" @on-change="changeTime3($event, 2)" :options="options" v-model="handleList.update_time_end" placeholder="请选择" class="iviewIptWidth250"></DatePicker>
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="SureHandImport">确定</span>
      </div>
    </Modal>
    <!-- 提示弹窗 -->
    <!-- <Modal v-model="tipStatus" label-position="left" width="400" :footer-hide="true" :mask-closable="false" :closable="false" class-name="vertical-center-modal">
      <div class="topModal clearfix">
        <div v-if="showName === 'success'" class="content">
          <img src="@/assets/images/tipsSuccessImg.png" class="tipsImg fl" />
          <div class="flDiv fl">
            <p>数据同步成功！</p>
          </div>
        </div>
        <div v-if="showName === 'fail'" class="content">
          <img src="@/assets/images/tipsDeleteImg.png" class="tipsImg fl" />
          <div class="flDiv fl">
            <p>数据同步失败！</p>
          </div>
        </div>
      </div>
      <div class="foot">
        <span class="pageBtn finger marginLeft20 btnSure" @click="tipStatus = false">确定</span>
      </div>
    </Modal> -->
    <Table :productList="listColumns" :productData="listData" border class="table" :rowClass="rowClassName"></Table>
    <template v-if="listData.length != 0">
      <div class="marginTop20">
        <Page v-if="total <= 60" :total="total" :current="pages.page" @on-change="changePage" class="fl page" />
        <Page v-if="total > 60" show-elevator :total="total" :current="pages.page" @on-change="changePage" class="fl page" />
        <div class="fr totalFont page">共计{{ total }}条记录</div>
      </div>
    </template>
  </div>
</template>

<script>
import Table from '@/components/table'
export default {
  name: 'OrderEqualRecord',
  components: {
    Table,
  },
  data() {
    return {
      IshandImport: false,
      tipStatus: false,
      showName: '',
      options: {},
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          maxWidth: 80,
          key: 'index',
        },
        {
          title: '订单编号',
          key: 'ebeln',
          align: 'center',
          width: 130,
        },
        {
          title: '申请单号',
          key: 'apply_order_code',
          align: 'center',
          width: 170,
        },
        {
          title: '客户名称',
          key: 'customer_name',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '数量',
          key: 'menge',
          align: 'center',
          width: 70,
          // render: (h, param) => {
          //   return h('div', [
          //     h(
          //       'span',
          //       {
          //         class: 'tableBlakcFont',
          //       },
          //       '¥' + param.row.purchase_amount
          //     ),
          //   ])
          // },
        },
        {
          title: '金额',
          key: 'netpr',
          width: 90,
          align: 'center',
        },
        {
          title: '订单时间',
          key: 'aedat',
          align: 'center',
          width: 130,
        },
        {
          title: '订单状态',
          key: 'order_status_str',
          width: 90,
          align: 'center',
          // render: (h, param) => {
          //   return h('div', [
          //     h(
          //       'span',
          //       {
          //         class: 'tableBlakcFont',
          //       },
          //       '¥' + param.row.sale_amount
          //     ),
          //   ])
          // },
        },
        {
          title: '更新时间',
          // key: 'update_time',
          align: 'center',
          width: 100,
          render: (h, param) => {
            if (this.$moment.unix(param.row.update_time).format('YYYY-MM-DD') !== '1970-01-01') {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'tableBlakcFont',
                  },
                  this.$moment.unix(param.row.update_time).format('YYYY-MM-DD')
                ),
              ])
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'tableBlakcFont',
                  },
                  ''
                ),
              ])
            }
          },
        },
        {
          title: '更新方式',
          width: 100,
          key: 'update_type_str',
          align: 'center',
        },
        {
          title: '申请者',
          width: 80,
          key: 'afnam',
          align: 'center',
        },
        {
          title: '采购组',
          width: 90,
          key: 'ekgrp',
          align: 'center',
        },
        {
          title: '备注',
          width: 110,
          key: 'potxt',
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          width: 100,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.$router.push({
                        path: '/OrderEqualRecordDetail',
                        query: {
                          ebeln: param.row.ebeln,
                          cusName: param.row.customer_name,
                        },
                      })
                    },
                  },
                },
                '详情'
              ),
            ])
          },
        },
      ],
      // 手动导入数据
      handleList: {
        update_time_start: '',
        update_time_end: '',
        ebeln: '',
      },
      // 查询参数
      queryFrom: {
        ebeln: '',
        afnam: '',
        ekgrp: '',
        potxt: '',
        apply_order_code: '',
        order_time_start: '',
        order_time_end: '',
        update_time_start: '',
        update_time_end: '',
      },
      pages: {
        page: 1,
        rows: 10,
      },
      total: 0,
      // 第一次进页面
      flag: false,
      searchData: {},
    }
  },
  mounted() {
    this.getOrderList()
  },
  methods: {
    // 反冲成功的行标红
    rowClassName(row, index) {
      if (String(row.menge).indexOf('.') > -1) return 'redRow'
      return ''
    },
    // 确定手动导入
    SureHandImport() {
      let data = this.handleList
      let pass = false
      if (data.ebeln || (data.update_time_start && data.update_time_end)) {
        this.$http.post(this.$api.orderSynRecord, data, true).then(res => {
          this.IshandImport = false
          this.handleList = {
            update_time_start: '',
            update_time_end: '',
            ebeln: '',
          }
          this.$Message.success('手动导入成功！')
          this.getOrderList()
        })
      } else {
        this.$Message.warning('请输入申请单号或者完整更新时间进行查询！')
      }
    },
    // 取消手动导入
    CancelHandImport() {
      this.IshandImport = false
      this.handleList = {
        update_time_start: '',
        update_time_end: '',
        ebeln: '',
      }
    },
    // 时间改变
    changeTime1(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.order_time_start = e
        console.log(that.queryFrom.order_time_start, 'z专户试试')
        that.queryFrom.order_time_end = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.order_time_start)
          },
        }
      } else {
        this.queryFrom.order_time_end = e
      }
    },
    changeTime2(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.update_time_start = e
        that.queryFrom.update_time_end = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.update_time_start)
          },
        }
      } else {
        this.queryFrom.update_time_end = e
      }
    },
    changeTime3(e, num) {
      if (num == 1) {
        let that = this
        that.handleList.update_time_start = e
        that.handleList.update_time_end = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.handleList.update_time_start)
          },
        }
      } else {
        this.handleList.update_time_end = e
      }
    },
    // 点击查询
    query() {
      this.pages.page = 1
      this.getOrderList()
    },
    // 页码改变
    changePage(e) {
      this.pages.page = e
      sessionStorage.setItem('page', this.pages.page)
      this.getOrderList()
    },
    // 产品同步记录
    getOrderList() {
      let data = {
        page: this.pages.page, // 页
        rows: this.pages.rows, // 行
        ebeln: this.queryFrom.ebeln,
        apply_order_code: this.queryFrom.apply_order_code,
        order_time_start: this.queryFrom.order_time_start,
        order_time_end: this.queryFrom.order_time_end,
        update_time_start: this.queryFrom.update_time_start,
        update_time_end: this.queryFrom.update_time_end,
        afnam: this.queryFrom.afnam,
        ekgrp: this.queryFrom.ekgrp,
        potxt: this.queryFrom.potxt,
      }
      this.$http.get(this.$api.orderSynRecord, data, true).then(res => {
        this.total = res.total
        this.listData = res.data
      })
    },
  },
}
</script>

<style scoped lang="less">
.marginTop20 {
  margin-top: 20px;
}
.MarginBottom20 {
  margin-bottom: 20px;
}
.table {
  /deep/ .ivu-table-summary {
    border-top: 0;
  }
}
.modalContent {
  padding-top: 30px;
  padding-bottom: 30px;
}
.modaltitle {
  margin-bottom: 13px;
  height: 22px;
  font-size: 16px;
  //font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 22px;
}
.foot {
  text-align: right;
}
.topModal {
  padding-bottom: 30px;
  margin-top: 30px;

  .content {
    display: flex;
    align-items: center;
    .tipsImg {
      width: 36px;
      height: 36px;
      margin: 0 13px 0 9px;
    }
    .flDiv {
      p:nth-child(1) {
        display: block;
        height: 24px;
        font-size: 18px;
        font-weight: 600;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
      }
    }
  }
}
</style>
